import React from "react";

import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div
      className="text-secondary text-center"
      style={{ padding: "3rem 1rem" }}
    >
      <h1>404 Page Not Found</h1>

      <Link
        to="/"
        className="button-primary"
        style={{ display: "inline-block" }}
      >
        Click Here to Go to Menu
      </Link>
    </div>
  );
};

export default Page404;
