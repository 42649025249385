import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routing from "./utils/Routing";

import Header from "./components/layout/Header";
import SecondaryBar from "./components/layout/SecondaryBar";
import Footer from "./components/layout/Footer";

import "./sass/App.scss";

const App = () => {
  return (
    <Router>
      <SecondaryBar />
      <Header />

      <Routing />

      <Footer />
    </Router>
  );
};

export default App;
