import React from "react";

import galleryData from "../../utils/galleryData";

import GalleryItem from "../gallery/GalleryItem";

const Gallery = () => {
  return (
    <div style={{ marginBottom: "5rem" }}>
      <h1
        className="text-secondary text-center"
        style={{ margin: "3rem", fontSize: "clamp(2rem, 5vw, 3.5rem)" }}
      >
        Gallery
      </h1>

      <div className="gallery flex">
        {galleryData.map(({ id, image, caption }) => (
          <GalleryItem key={id} caption={caption} image={image} />
        ))}
      </div>
    </div>
  );
};

export default Gallery;
