import React from "react";

const MenuCustomTable = ({ headers, data }) => {
  const thStyle = {
    fontSize: "12px",
  };

  const tbodyStyle = {
    textAlign: "initial",
  };

  return (
    <>
      <h3 className="text-primary-text-center">{data.type}</h3>
      <div className="flex">
        <table>
          <thead>
            <tr>
              {headers.map((item, i) => (
                <th style={thStyle} key={i}>
                  <span>{item}</span>
                </th>
              ))}
            </tr>
          </thead>

          <tbody style={tbodyStyle}>
            {data.list.map((item) => (
              <tr key={item.id}>
                <td>
                  <span>{item.title}</span>
                  <br />
                  <small>({item.location})</small>
                </td>
                <td>${item.prices[0]}</td>
                <td>${item.prices[1]}</td>
                <td>${item.prices[2]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MenuCustomTable;
