import React from "react";
import PropTypes from "prop-types";

const GalleryItem = ({ caption, image }) => {
  return (
    <div className="gallery-item">
      <img src={image} className="gallery-image" alt="Gallery Item" />
      <p className="item-title">{caption}</p>
    </div>
  );
};

GalleryItem.propTypes = {
  caption: PropTypes.string,
  image: PropTypes.string,
};

export default GalleryItem;
