import React from "react";

import Information from "../about/Information";

const About = () => {
  return (
    <div>
      <Information />
    </div>
  );
};

export default About;
