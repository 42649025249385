import React from "react";

// import { AiFillPhone, AiFillEnvironment } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";

import { Link } from "react-router-dom";

const SecondaryBar = () => {
  const iconStyle = {
    fontSize: "1.75rem",
  };

  return (
    <div className="top-bar">
      <div style={{ width: "95%", margin: "auto" }}>
        <AiFillPhone className="text-light" style={iconStyle} />{" "}
        <a href="tel:905 528 0689">(905) 528-0689</a>
        <div className="details">
          <span>
            We Are Open! Curbside Pickup and Patio/Indoor Dining Available!
          </span>
          <Link to="/curbside-dining" className="button">
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SecondaryBar;
