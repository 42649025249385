import galleryImg1 from "../assets/images/gallery/gallery1-min.jpg";
// import galleryImg2 from "../assets/images/gallery/gallery2-min.jpg";
import galleryImg3 from "../assets/images/gallery/gallery3-min.jpg";
import galleryImg4 from "../assets/images/gallery/gallery4-min.jpg";
import galleryImg5 from "../assets/images/gallery/gallery5-min.jpg";
import galleryImg6 from "../assets/images/gallery/gallery6-min.jpg";
import galleryImg7 from "../assets/images/gallery/gallery7-min.jpg";
import galleryImg8 from "../assets/images/gallery/gallery8-min.jpg";
import galleryImg9 from "../assets/images/gallery/gallery9-min.jpg";
import galleryImg10 from "../assets/images/gallery/gallery10-min.jpg";
// import galleryImg11 from "../assets/images/gallery/gallery11-min.jpg";
import galleryImg12 from "../assets/images/gallery/gallery12-min.jpg";
import galleryImg13 from "../assets/images/gallery/gallery13-min.jpg";
import galleryImg14 from "../assets/images/gallery/gallery14-min.jpg";
import galleryImg15 from "../assets/images/gallery/gallery15-min.jpg";
import galleryImg16 from "../assets/images/gallery/gallery16-min.jpg";
import galleryImg17 from "../assets/images/gallery/gallery17-min.jpg";

const galleryImages = [
  {
    id: 0,
    image: galleryImg1,
    caption: "THE STEAK THAT WILL TAKE YOUR MIND AWAY",
  },
  { id: 1, image: galleryImg3, caption: "Seafood?" },
  { id: 2, image: galleryImg4, caption: "Jumbo Shrimp Cocktail" },
  { id: 3, image: galleryImg5, caption: "CAESAR SALAD, FEEL LIKE A CAESAR" },
  { id: 4, image: galleryImg6, caption: "PASTA THE WAY IT SHOULD BE" },
  { id: 5, image: galleryImg7, caption: "MEET FRANCO PUTIGNANO" },
  {
    id: 6,
    image: galleryImg8,
    caption: "OUR FINEST COLLECTION OF WINE AND COCKTAILS",
  },
  { id: 7, image: galleryImg9, caption: "Classic Coffee" },
  {
    id: 8,
    image: galleryImg10,
    caption: "OUR FINEST COLLECTION OF WINE AND COCKTAILS",
  },
  // { id: 9, image: galleryImg11, caption: "Craving Some Chocolate?" },
  { id: 10, image: galleryImg12, caption: "Sweet Seduction" },
  { id: 11, image: galleryImg13, caption: "Craving Some Chocolate?" },
  { id: 12, image: galleryImg14, caption: "Our Family" },
  { id: 13, image: galleryImg15, caption: "Our Family" },
  { id: 14, image: galleryImg16, caption: "Our Family" },
  { id: 15, image: galleryImg17, caption: "CASA MIA E CASA VOSTRA" },
];

export default galleryImages;
