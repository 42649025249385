import React, { useState, memo } from "react";
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "500px",
};

const position = { lat: 43.2536184, lng: -79.8623945 };

const Maps = () => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={19}
        options={{
          gestureHandling: "greedy",
          scrollwheel: false,
        }}
      >
        <>
          {showInfoWindow && (
            <InfoWindow
              position={position}
              onCloseClick={() => setShowInfoWindow(false)}
            >
              <div style={{ background: `white` }}>
                <h4 className="text-secondary">Shakespeares</h4>

                <a
                  className="text-primary"
                  href="https://www.google.com/maps/place/Shakespeare's+Steak+and+Seafood/@43.2536184,-79.8623945,15z/data=!4m2!3m1!1s0x0:0x88f51fd532ff4909?sa=X&ved=2ahUKEwjQm8PK84ftAhVaVc0KHaZvCoAQ_BIwCnoECA8QBQ"
                >
                  181 Main St E, <br />
                  Hamilton, ON L8N 1H2
                </a>
                <br />
                <a className="text-primary" href="905-528-0689">
                  (905) 528-0689
                </a>
              </div>
            </InfoWindow>
          )}

          <Marker position={position} onClick={() => setShowInfoWindow(true)} />
        </>
      </GoogleMap>
    </LoadScript>
  );
};

export default memo(Maps);
