import React from "react";

import MenuTable from "../curbside-dining/MenuTable";

import patioImage from "../../assets/images/patio.jpg";

const CurbsideAndDining = () => {
  return (
    <div className="container">
      <div className="section">
        <h1>Shakespeare's is now Offering Curbside Pickup!</h1>

        <h2 className="text-center">
          We are please to announce that we will be starting curbside pickup as
          of Thursday, May 14th
        </h2>

        <p>
          Orders may be placed by phone:{" "}
          <a href="tel:905 528-0689">(905) 528-0689</a> or by email:{" "}
          <a href="mailto:shakescurbside@gmail.com">shakescurbside@gmail.com</a>
        </p>

        <p>
          Effective Tuesday September 15th, curbside pickup hours will be 5:00pm
          - 8:30pm Tuesdays through Saturdays
        </p>

        <p>
          All of our wines from our list are available to add to your order at{" "}
          <span>30% off!</span>
        </p>
      </div>

      <div className="section">
        <MenuTable />
      </div>

      <div className="section">
        <h1>Enjoy Outdoor and Indoor Dining at its Finest!</h1>

        <h2>
          Call us at <a href="tel:905 528-0689">(905) 528-0689</a> to Reserve
          your Table!
        </h2>

        <h2>
          Patio/Inside Dining Hours
          <br />
          (Effective Tuesday, September 15th)
        </h2>

        <p>
          Lunch: <strong>Wednesdays to Fridays from 12:00pm to 2:00pm</strong>
        </p>

        <p>
          Dinner: <strong>Tuesdays to Saturdays from 5:00pm to 9:30pm</strong>
        </p>

        <p>* Our Patio will be Open Weather Permitting *</p>

        <img src={patioImage} alt="Outdoor Patio" style={{display: 'block', margin: 'auto'}} />
      </div>
    </div>
  );
};

export default CurbsideAndDining;
