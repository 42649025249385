import React from "react";
import PropTypes from "prop-types";

import MenuCustomTable from "./MenuCustomTable";

const MenuListItem = ({ title, subtitle, description, image, menuList }) => {
  return (
    <div style={{ margin: "2rem 0" }}>
      <h1 className="text-primary text-center">{title}</h1>

      <h3 className="text-primary">{subtitle}</h3>

      <div className="menu-area flex">
        <img src={image} alt="" />
        <p className="description">{description}</p>
      </div>

      <div className="flex">
        {menuList.map(({ id, sectionTitle, items }) =>
          sectionTitle === "Wines By the Glass" ? (
            <div key={id}>
              {items.map((item) => (
                <MenuCustomTable
                  key={item.id}
                  title={sectionTitle}
                  data={item}
                  headers={["", "Glass (8 oz.)", "1/2 litre", "1 litre"]}
                />
              ))}
            </div>
          ) : (
            <div key={id}>
              <h3 className="text-primary-text-center">{sectionTitle}</h3>

              <table style={{ textAlign: "initial" }}>
                <tbody>
                  {items.map((item) => (
                    <tr key={item.id}>
                      {item.price === 0 ? (
                        <td colSpan="2" className="text-center">
                          <span>{item.title}</span>
                          <br />
                          <small>{item.description}</small>
                        </td>
                      ) : (
                        <>
                          <td>
                            <span>{item.title}</span>
                            <br />
                            <small>{item.description}</small>
                          </td>
                          <td className="menu-price">
                            ${item.price.toFixed(2)}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}
      </div>
    </div>
  );
};

MenuListItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  menuList: PropTypes.array.isRequired,
};

export default MenuListItem;
