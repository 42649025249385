import React from "react";

const Reservation = () => {
  return (
    <div>
      <h1>Reservation</h1>
    </div>
  );
};

export default Reservation;
