import React from "react";

import { Switch, Route } from "react-router-dom";

import Home from "../components/pages/Home";
import About from "../components/pages/About";
import Menu from "../components/menu/Menu";
import MenuListItemWrapper from "../components/menu/MenuListItemWrapper";
import More from "../components/pages/More";
import Gallery from "../components/pages/Gallery";
import Reservation from "../components/pages/Reservation";
import Contact from "../components/pages/Contact";
import CurbsideAndDining from "../components/pages/CurbsideAndDining";
import Page404 from "../components/pages/Page404";

const Routing = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route exact path="/menu" component={Menu} />
      <Route exact path="/menu/:title" component={MenuListItemWrapper} />
      <Route path="/more" component={More} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/reservation" component={Reservation} />
      <Route path="/contact" component={Contact} />
      <Route path="/curbside-dining" component={CurbsideAndDining} />
      <Route path="*" component={Page404} />
    </Switch>
  );
};

export default Routing;
