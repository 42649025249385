import React from "react";
import { AiFillPhone, AiFillEnvironment } from "react-icons/ai";

import GoogleMap from "../contact/GoogleMap";

const Contact = () => {
  return (
    <>
      <div className="container-fluid">
        <h1>Contact</h1>

        <p className="text-center" style={{ marginBottom: "1.5rem" }}>
          *Most Credit Cards Accepted
        </p>

        <div className="flex" style={{ marginBottom: "2rem" }}>
          <div>
            <div className="contact-item">
              <AiFillPhone />
              <a href="905-528-0689">(905) 528-0689</a>
            </div>

            <div className="contact-item">
              <AiFillEnvironment />
              <a
                href="https://www.google.com/maps/place/Shakespeare's+Steak+and+Seafood/@43.2536184,-79.8623945,15z/data=!4m2!3m1!1s0x0:0x88f51fd532ff4909?sa=X&ved=2ahUKEwjQm8PK84ftAhVaVc0KHaZvCoAQ_BIwCnoECA8QBQ"
                target="_blank"
                rel="noreferrer"
              >
                181 Main St E, Hamilton, ON L8N 1H2
              </a>
            </div>
          </div>

          <div>
            <h3>Opening Hours (Effective September 15th):</h3>

            <table>
              <tbody>
                <tr>
                  <td className="text-secondary">
                    Wednesday, Thursday & Friday Lunches
                  </td>
                  <td>12:00pm to 2:00pm</td>
                </tr>

                <tr>
                  <td className="text-secondary">Tuesday to Saturday Dinner</td>
                  <td>5:00pm to 9:30pm</td>
                </tr>

                <tr>
                  <td className="text-secondary">Sunday and Monday</td>
                  <td>Closed</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <GoogleMap />
    </>
  );
};

export default Contact;
