import React from "react";

import {
  appetizers,
  salads,
  entrees,
  pasta,
  addons,
  cookYourOwnCuts,
  desserts,
} from "../../utils/curbsideData";

const MenuTable = () => {
  return (
    <>
      <div className="flex">
        <div>
          <h2>Appetizers</h2>
          <table>
            <tbody>
              {appetizers.map((item) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.name}</span>
                    <br />
                    <small>{item.additionalInfo}</small>
                  </td>
                  <td className="menu-price">${item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <h2>Salads</h2>
          <table>
            <tbody>
              {salads.map((item) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.name}</span>
                    <br />
                    <small>{item.additionalInfo}</small>
                  </td>
                  <td className="menu-price">${item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <h2>Entrees</h2>
          <table>
            <tbody>
              {entrees.map((item) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.name}</span>
                    <br />
                    <small>{item.additionalInfo}</small>
                  </td>
                  <td className="menu-price">${item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <h2>Pasta</h2>
          <table>
            <tbody>
              {pasta.map((item) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.name}</span>
                    <br />
                    <small>{item.additionalInfo}</small>
                  </td>
                  <td className="menu-price">${item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <h2>Add Ons</h2>
          <table>
            <tbody>
              {addons.map((item) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.name}</span>
                    <br />
                    <small>{item.additionalInfo}</small>
                  </td>
                  <td className="menu-price">${item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <h2>Cook your Own Cuts</h2>
          <table>
            <tbody>
              {cookYourOwnCuts.map((item) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.name}</span>
                    <br />
                    <small>{item.additionalInfo}</small>
                  </td>
                  <td className="menu-price">${item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <h2>Desserts</h2>
          <table>
            <tbody>
              {desserts.map((item) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.name}</span>
                    <br />
                    <small>{item.additionalInfo}</small>
                  </td>
                  <td className="menu-price">${item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MenuTable;
