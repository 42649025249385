import React, { useRef } from "react";

import logo from "../../assets/logos/logo-main.png";

import { Link } from "react-router-dom";

const Header = () => {
  const navbarNode = useRef(null);

  const toggleClick = () => {
    navbarNode.current.checked = !navbarNode.current.checked;
  };

  return (
    <header className="header">
      <Link to="/" onClick={toggleClick}>
        <img src={logo} alt="Logo" className="logo" />
      </Link>

      <input
        type="checkbox"
        id="nav-toggle"
        className="nav-toggle"
        ref={navbarNode}
      />

      <nav>
        <ul>
          <li>
            <Link to="/about" onClick={toggleClick}>
              About
            </Link>
          </li>
          <li>
            <Link to="/Menu" onClick={toggleClick}>
              Menu
            </Link>
          </li>
          {/* <li>
            <Link to="/more" onClick={toggleClick}>
              More
            </Link>
          </li> */}
          <li>
            <Link to="/gallery" onClick={toggleClick}>
              Gallery
            </Link>
          </li>
          {/* <li>
            <Link to="/gallery" onClick={toggleClick}>
              Reservation
            </Link>
          </li> */}
          <li>
            <Link to="/contact" onClick={toggleClick}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>

      <label htmlFor="nav-toggle" className="nav-toggle-label">
        <span></span>
      </label>
    </header>
  );
};

export default Header;
