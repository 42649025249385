import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import MenuListItem from "./MenuListItem";

import { getMenu } from "../../utils/menuData";

const MenuListItemWrapper = ({ match }) => {
  const [item, setItem] = useState({
    id: 0,
    image: "",
    title: "",
    type: "",
    menu: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const foundItem = getMenu().find((item) =>
      item.type.includes(match.params.title)
    );
    setLoading(false);

    if (foundItem) setItem(foundItem);
  }, [match.params.title]);

  return (
    <div className="container text-center" style={{ marginBottom: "4rem" }}>
      {!loading && item.menu.length === 0 && (
        <>
          <h1 className="text-primary">No Menu Found</h1>
          <Link to="/menu" className="button-primary text-center">
            Back To All Menus
          </Link>
        </>
      )}

      {!loading && item.menu.length > 0 && (
        <div style={{ marginTop: "3rem" }}>
          <MenuListItem
            title={item.title}
            description={item.description}
            image={item.image}
            menuList={item.menu}
          />
          <Link to="/menu" className="button-primary text-center">
            Back To Menu
          </Link>
        </div>
      )}
    </div>
  );
};

export default MenuListItemWrapper;
