import React from "react";

import { AiFillPhone, AiFillEnvironment } from "react-icons/ai";

const Footer = () => {
  const iconStyle = {
    fontSize: "1.75rem",
  };

  return (
    <footer className="bg-primary text-light">
      <div className="container">
        <p>
          <AiFillPhone className="text-light" style={iconStyle} />{" "}
          <a href="tel:905 528 0689">(905) 528-0689</a>
        </p>

        <p>
          <AiFillEnvironment className="text-light" style={iconStyle} />{" "}
          <a href="https://www.google.com/maps/place/Shakespeare's+Steak+and+Seafood/@43.2536184,-79.8623945,15z/data=!4m2!3m1!1s0x0:0x88f51fd532ff4909?sa=X&ved=2ahUKEwjQm8PK84ftAhVaVc0KHaZvCoAQ_BIwCnoECA8QBQ">
            181 Main St. E, Hamilton, Ontario, L8N 1H2
          </a>
        </p>

        <p>Copyright &copy; Shakespeare's 2014.</p>

        <p>Designed by Zing Media</p>
      </div>
    </footer>
  );
};

export default Footer;
