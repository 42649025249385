import dinnerImage from "../assets/images/dinnerbg.jpg";
// import lunchImage from "../assets/images/lunchbg.jpg";
import wineCocktailImage from "../assets/images/winecocktailbg.jpg";
import dessertImage from "../assets/images/dessertbg.jpg";

const lunchAndDinner = {
  id: 0,
  type: "lunch-dinner",
  title: "Lunch/Dinner",
  description: "",
  image: dinnerImage,
  menu: [
    {
      id: 4,
      sectionTitle: "Appetizers",
      items: [
        {
          id: 0,
          title: "Jumbo Tiger Shrimp Cocktail",
          description: "",
          price: 30,
        },
        {
          id: 1,
          title: "Bacon Wrapped Scallops",
          description: "",
          price: 27,
        },
        { id: 2, title: "Fresh Oysters", description: "", price: 24 },
        { id: 3, title: "Fried Calamari", description: "", price: 18 },
        {
          id: 4,
          title: "Escargots Bourguignonne",
          description: "White wine, garlic & spices",
          price: 14,
        },
        {
          id: 5,
          title: "Smoked Salmon",
          description: "With olive oil, onions & capers",
          price: 15,
        },
        { id: 6, title: "Soup of the Day", description: "", price: 9 },
        {
          id: 7,
          title: "Manhattan Style Seafood Chowder",
          description: "",
          price: 13,
        },
        {
          id: 8,
          title: "French Onion Soup",
          description: "Au gratin",
          price: 13,
        },
        {
          id: 9,
          title: "Charcuterie Board",
          description: "For 2",
          price: 40,
        },
      ],
    },

    {
      id: 5,
      sectionTitle: "Salads",
      items: [
        {
          id: 0,
          title: "Wedge Salad",
          description: "Iceberg with blue cheese dressing",
          price: 14,
        },
        {
          id: 1,
          title: "Caprese Salad",
          description: "Tomatoes, bocconcini, onions & basil",
          price: 16,
        },
        { id: 2, title: "Caeser Salad", description: "", price: 16 },
        {
          id: 3,
          title: "Spinach",
          description: "Mushrooms, bacon, onions, with balsamic vinaigrette",
          price: 17,
        },
        { id: 4, title: "Greek Salad", description: "", price: 18 },
        {
          id: 5,
          title: "",
          description: "* Add chicken to any salad *",
          price: 8,
        },
      ],
    },

    {
      id: 0,
      sectionTitle: "Entrees",
      items: [
        {
          id: 0,
          title: "9 oz. Filet Mignon",
          description: "Bacon wrapped",
          price: 49,
        },
        {
          id: 1,
          title: "5 oz. Petit Filet Mignon",
          description: "Bacon wrapped",
          price: 32,
        },
        {
          id: 2,
          title: "12 oz. New York Striploin",
          description: "Center cut",
          price: 52,
        },
        {
          id: 3,
          title: "9 oz. New York Sirloin",
          description: "Center cut",
          price: 43,
        },
        {
          id: 4,
          title: "9 oz. New York Pepper Steak",
          description: "Center cut - black pepper, wine & cognac",
          price: 47,
        },
        {
          id: 5,
          title: "24 oz. Bone in Ribeye Steak",
          description: "",
          price: 67,
        },
        { id: 6, title: "10 oz. Ribeye Steak", description: "", price: 41 },
        { id: 7, title: "Rack of Lamb", description: "Full rack", price: 57 },
        {
          id: 8,
          title: "Sir John Falstaff Pork Back Ribs",
          description: "Full rack",
          price: 38,
        },
        {
          id: 9,
          title: "6 oz. Grilled Chicken Breast",
          description: "",
          price: 18,
        },
        {
          id: 10,
          title: "7 oz. New York Steak Sandwich",
          description: "With fries",
          price: 28,
        },
        {
          id: 11,
          title: "Gourmet Burger",
          description:
            "Bacon, cheese, pickles, onion, lettuce and tomatoes with fries",
          price: 25,
        },
        {
          id: 12,
          title: "Hamlet's Joy",
          description: "5 oz. bacon wrapped filet & grilled shrimps",
          price: 55,
        },
      ],
    },

    {
      id: 6,
      sectionTitle: "Fish & Seafood",
      items: [
        {
          id: 0,
          title: "Alaskan King Crab Legs",
          description: "1 LB steamed",
          price: 99,
        },
        {
          id: 1,
          title: "Surf & Turf",
          description: "5 oz. filet mignon w/ 10 oz. lobster tail",
          price: 75,
        },
        {
          id: 2,
          title: "Maritime Pleasure",
          description: "King crab, scallops, shrimp & calmari",
          price: 59,
        },
        { id: 3, title: "Lobster Tail", description: "10 oz.", price: 50 },
        {
          id: 4,
          title: "Broiled Tiger Shrimps",
          description: "With fresh garlic & lemon",
          price: 43,
        },
        { id: 5, title: "Grilled Swordfish", description: "", price: 42 },
        { id: 6, title: "Grilled Branzino", description: "", price: 39 },
        {
          id: 7,
          title: "Filet of Rainbow Trout",
          description: "Baked w/ white wine & lemon",
          price: 36,
        },
        {
          id: 8,
          title: "Filet of Salmon",
          description: "Baked w/ white wine & lemon",
          price: 28,
        },
      ],
    },

    {
      id: 1,
      sectionTitle: "Pasta",
      items: [
        {
          id: 0,
          title: "Chicken Parmigiana",
          description: "With penne pomodoro",
          price: 28,
        },
        {
          id: 1,
          title: "Meat Cannelloni",
          description: "Tomato sauce",
          price: 26,
        },
        {
          id: 2,
          title: "Penne Pomodoro",
          description: "Tomato, garlic & basil",
          price: 20,
        },
      ],
    },

    {
      id: 2,
      sectionTitle: "Sides",
      items: [
        { id: 0, title: "French Fries", description: "", price: 7 },
        { id: 1, title: "Onion Rings", description: "", price: 9 },
        {
          id: 2,
          title: "Mushrooms",
          description: "Sauted with garlic, lemon & olive oil",
          price: 9,
        },
        {
          id: 3,
          title: "Rapini",
          description: "Sauted with garlic, lemon & olive oil",
          price: 9,
        },
      ],
    },

    // {
    //   id: 3,
    //   sectionTitle: "Desserts",
    //   items: [
    //     {
    //       id: 0,
    //       title: "New York Style Cheesecake w/cherries",
    //       description: "",
    //       price: 14,
    //     },
    //     { id: 1, title: "Hot Apple Beignets", description: "", price: 12 },
    //     { id: 2, title: "Vanilla Creme Brulee", description: "", price: 12 },
    //     { id: 3, title: "Dark Chocolate Cake", description: "", price: 10 },
    //   ],
    // },
  ],
};

// const lunch = {
//   id: 1,
//   type: "lunch",
//   title: "Lunch",
//   description: `
//   We proudly serve CERTIFIED U.S. ANGUS BEEF. Beef so exceptional that it is certified by the USDA grading
//   service to meet the program rigid standards for excellence in marbling, texture and firmness.
//   It is the top of the line beef that is always tender, juicy and the very best of the taste.
//   `,
//   image: lunchImage,
//   menu: [...dinner.menu],
// };

const wine = {
  id: 2,
  type: "wine",
  title: "Wine",
  description: "",
  image: wineCocktailImage,
  menu: [
    {
      id: 0,
      sectionTitle: "Wines By the Glass",
      items: [
        {
          id: 0,
          type: "White Whites (By the Glass)",
          list: [
            {
              id: 0,
              title: "Cave Spring Rielsing",
              location: "Canada",
              prices: [16, 32, 64],
            },
            {
              id: 1,
              title: "Mezzacorona Pinot Grigio",
              location: "Italy",
              prices: [14, 28, 56],
            },
            {
              id: 2,
              title: "Philippe Rothschild Sauvignon Blanc",
              location: "France",
              prices: [14, 26, 56],
            },
            {
              id: 3,
              // title: "Santa Carolina Chardonnay Reserve",
              title: "Canyon Road Chardonnay",
              location: "USA",
              prices: [13, 26, 52],
            },
            {
              id: 4,
              title: "E & J Gallo White Zinfandel",
              location: "USA",
              prices: [10, 20, 40],
            },
          ],
        },
        {
          id: 1,
          type: "Red Wines (By the Glass)",
          list: [
            {
              id: 0,
              title: "Canyon Road Cabernet Sauvignon",
              location: "USA",
              prices: [14, 28, 56],
            },
            {
              id: 1,
              title: "Jacob's Creek Shiraz",
              location: "Australia",
              prices: [13, 26, 52],
            },
            {
              id: 2,
              title: "Pasqua Merlot",
              location: "Italy",
              prices: [10, 20, 40],
            },
            {
              id: 3,
              title: "Finca Flinchman Malbec",
              location: "Argentina",
              prices: [10, 20, 40],
            },
          ],
        },
      ],
    },
    {
      id: 1,
      sectionTitle: "Half Bottles",
      items: [
        {
          id: 0,
          title: "Santa Margarita Pinot Grigio",
          description: "White Wine (Italy)",
          price: 30,
        },
        {
          id: 1,
          title: "Kim Crawford Sauvignon Blanc",
          description: "White Wine (New Zealand)",
          price: 27,
        },
        {
          id: 2,
          title: "Wolfblass Chardonnay",
          description: "White Wine (Australia)",
          price: 25,
        },
        {
          id: 3,
          title: "CastelGiocondo Brunello Di Montalcino",
          description: "Red Wine (Italy)",
          price: 75,
        },
        {
          id: 4,
          title: "Masi Amarone Della Valpolicella Classico",
          description: "Red Wine (Italy)",
          price: 59,
        },
        {
          id: 5,
          title: "J. Lohr Cabernet Sauvignon",
          description: "Red Wine (U.S.A)",
          price: 35,
        },
        {
          id: 6,
          title: "J. Lohr Merlot",
          description: "Red Wine (U.S.A)",
          price: 35,
        },
        {
          id: 7,
          title: "Masi Campofiorin",
          description: "Red Wine (Italy)",
          price: 30,
        },
        {
          id: 8,
          title: "Folonari Valpolicella",
          description: "Red Wine (Italy)",
          price: 23,
        },
        {
          id: 9,
          title: "Santa Caroliona Cabernet Sauvignon Reserva",
          description: "Red Wine (Chile)",
          price: 20,
        },
        {
          id: 10,
          title: "Caymus Cabernet Sauvignon",
          description: "(Napa Valley USA)",
          price: 130,
        },
        // {
        //   id: 5,
        //   title: "La Crema Pinot Noir",
        //   description: "Red Wine (U.S.A)",
        //   price: 45,
        // },
      ],
    },
    {
      id: 2,
      sectionTitle: "Champagnes & Sparkling Wines",
      items: [
        {
          id: 0,
          title: "Dom Perignon",
          description: "French",
          price: 390,
        },
        {
          id: 1,
          title: "Veuve Cliquot",
          description: "French",
          price: 175,
        },
        {
          id: 2,
          title: "Prosecco",
          description: "Italian",
          price: 50,
        },
        {
          id: 3,
          title: "Prosecco - Piccolo (200 ml)",
          description: "Italian",
          price: 15,
        },
      ],
    },
    {
      id: 3,
      sectionTitle: "Red Wines",
      items: [
        {
          id: 0,
          title: "Tawse Meritage Reserve",
          description: "Canadian",
          price: 120,
        },
        {
          id: 1,
          title: "Two Sisters Eleventh Post",
          description: "Canadian",
          price: 100,
        },
        {
          id: 2,
          title: "Tawse Cabernet Franc",
          description: "Canadian",
          price: 80,
        },
        {
          id: 3,
          title: "Peninsula Ridge Estates Merlot",
          description: "Canadian",
          price: 42,
        },
        {
          id: 4,
          title: "Peninsula Ridge Estates Carbernet-Merlot",
          description: "Canadian",
          price: 42,
        },
      ],
    },
    {
      id: 4,
      sectionTitle: "White Wines",
      items: [
        {
          id: 0,
          title: "Henry of Pelham Cardonnay Reserve",
          description: "Canadian",
          price: 42,
        },
        {
          id: 1,
          title: "Peninsula Ridge Suavignon Blanc",
          description: "Canadian",
          price: 42,
        },
        {
          id: 2,
          title: "Peninsula Ridge Chardonnay",
          description: "Canadian",
          price: 42,
        },
        {
          id: 3,
          title: "J. Lohr Chardonnay - Sonoma Valley",
          description: "California",
          price: 55,
        },
        {
          id: 4,
          title: "Wente Chardonnay",
          description: "California",
          price: 55,
        },
        {
          id: 5,
          title: "Pouilly Fuisse",
          description: "French",
          price: 85,
        },
        {
          id: 6,
          title: "Sancerre",
          description: "French",
          price: 85,
        },
        {
          id: 7,
          title: "Petit Chablis",
          description: "French",
          price: 65,
        },
        {
          id: 8,
          title: "Santa Margarita Pinot Grigio",
          description: "Italian",
          price: 55,
        },
        {
          id: 9,
          title: "Wolfblass Chardonnay",
          description: "Australian",
          price: 45,
        },
        {
          id: 10,
          title: "Lindemans Bin 65 Chardonnay",
          description: "Australian",
          price: 30,
        },
        {
          id: 11,
          title: "Kim Crawford Sauvignon Blanc",
          description: "New Zealand",
          price: 55,
        },
      ],
    },
    {
      id: 5,
      sectionTitle: "Red Wine (Special Selection)",
      items: [
        {
          id: 0,
          title: "Chateau Pipeau - St. Emilion Grand Cru '16",
          description: "French Bordeaux",
          price: 115,
        },
        {
          id: 1,
          title: "Chateau Argadens - Grand Vin Bordeaux Superieur '17",
          description: "French Bordeaux",
          price: 55,
        },
        {
          id: 2,
          title: "Ornellaia Tenuta Dell '12",
          description: "Italian",
          price: 395,
        },
        {
          id: 3,
          title: "Tignanello - Antinori '17",
          description: "Italian",
          price: 295,
        },
        {
          id: 4,
          title: "Barolo - Ascheri '13",
          description: "Italian",
          price: 150,
        },
        {
          id: 5,
          title: "Brunello Di Montalcino - Banfi '14",
          description: "Italian",
          price: 150,
        },
        {
          id: 6,
          title: "Caymus Cabernet Sauvignon - Napa Valley '17 (1 ltr)",
          description: "California",
          price: 325,
        },
        {
          id: 7,
          title: "Cliff Lede Cabernet Sauvignon - Napa Valley '16",
          description: "California",
          price: 275,
        },
        {
          id: 8,
          title: "Stag's Leap Artemis - Napa Valley '17",
          description: "California",
          price: 215,
        },
        {
          id: 9,
          title: "Caymus Zinfandel - Napa Valley '17",
          description: "California",
          price: 160,
        },
        {
          id: 10,
          title: "Stag's Leap Cabernet Sauvignon - Napa Valley '16",
          description: "California",
          price: 150,
        },
        {
          id: 11,
          title: "Seghesio Zinfandel - Sonoma County '17",
          description: "California",
          price: 80,
        },
        {
          id: 12,
          title: "Irony Cabernet Sauvignon - Norht Coast '16",
          description: "California",
          price: 69,
        },
      ],
    },
  ],
};

const cocktails = {
  id: 3,
  type: "cocktails",
  title: "Cocktails",
  description: "",
  image: wineCocktailImage,
  menu: [
    {
      id: 0,
      sectionTitle: "Cocktails",
      items: [
        {
          id: 0,
          title: "Black Russian",
          description: "",
          price: 13,
        },
        {
          id: 1,
          title: "Bloody Caesar",
          description: "",
          price: 11,
        },
        {
          id: 2,
          title: "Bloody Mary",
          description: "",
          price: 11,
        },
        {
          id: 3,
          title: "Long Island Iced Tea",
          description: "",
          price: 13,
        },
        {
          id: 4,
          title: "Champagne Cocktail",
          description: "",
          price: 15,
        },
        {
          id: 5,
          title: "Daquiri",
          description: "",
          price: 9,
        },
        {
          id: 6,
          title: "Pina Colada",
          description: "",
          price: 9,
        },
        {
          id: 7,
          title: "Rusty Nail",
          description: "",
          price: 13,
        },
        {
          id: 8,
          title: "Singapore Sling",
          description: "",
          price: 11,
        },
        {
          id: 9,
          title: "Margarita",
          description: "",
          price: 13,
        },
        {
          id: 10,
          title: "Zombie",
          description: "",
          price: 13,
        },
        {
          id: 11,
          title: "Manhattan (3 oz.)",
          description: "",
          price: 15,
        },
        {
          id: 12,
          title: "King Manhattan (4 oz.)",
          description: "",
          price: 17,
        },
      ],
    },
    {
      id: 1,
      sectionTitle: "Martinis",
      items: [
        {
          id: 0,
          title: "Martini (3 oz.)",
          description: "",
          price: 15,
        },
        {
          id: 1,
          title: "King Martini (4 oz.)",
          description: "",
          price: 17,
        },
        {
          id: 2,
          title: "Apple Martini",
          description: "",
          price: 17,
        },
        {
          id: 3,
          title: "Chocolate Martini",
          description: "",
          price: 17,
        },
        {
          id: 4,
          title: "Cosmopolitan",
          description: "",
          price: 17,
        },
      ],
    },
    {
      id: 2,
      sectionTitle: "Aperitifs",
      items: [
        {
          id: 0,
          title: "Vermouth (Sweet or Dry)",
          description: "",
          price: 9,
        },
        {
          id: 1,
          title: "Amaro Averna",
          description: "",
          price: 9,
        },
        {
          id: 2,
          title: "Campari",
          description: "",
          price: 9,
        },

        {
          id: 3,
          title: "Dubonnet",
          description: "",
          price: 9,
        },
      ],
    },
    {
      id: 3,
      sectionTitle: "Single Malt",
      items: [
        {
          id: 0,
          title: "McClelland Speyside",
          description: "",
          price: 9.5,
        },
        {
          id: 1,
          title: "Bowmore 12YR",
          description: "",
          price: 10.5,
        },
        {
          id: 2,
          title: "Glenfiddich 12YR",
          description: "",
          price: 10.5,
        },
        {
          id: 3,
          title: "Glenlivt 12YR",
          description: "",
          price: 10.5,
        },
        {
          id: 4,
          title: "Glenmorganie",
          description: "",
          price: 12.5,
        },
        {
          id: 5,
          title: "Laphroaig",
          description: "",
          price: 13.5,
        },
        {
          id: 6,
          title: "Macallan Gold",
          description: "",
          price: 14.5,
        },
        {
          id: 7,
          title: "Dalwhinne 15YR",
          description: "",
          price: 17,
        },
        {
          id: 8,
          title: "Oban 14YR",
          description: "",
          price: 20,
        },
        {
          id: 9,
          title: "Lagavulin 16YR",
          description: "",
          price: 21.5,
        },
        {
          id: 10,
          title: "Glenfiddich 21YR",
          description: "",
          price: 69,
        },
      ],
    },
    {
      id: 4,
      sectionTitle: "Bourbon",
      items: [
        {
          id: 0,
          title: "Jack Daniels",
          description: "",
          price: 8,
        },
        {
          id: 1,
          title: "Jim Beam",
          description: "",
          price: 8,
        },
        {
          id: 2,
          title: "Maker's Mark (1.25 oz)",
          description: "",
          price: 11,
        },
        {
          id: 3,
          title: "Woodford Reserve (1.25 oz)",
          description: "",
          price: 11,
        },
      ],
    },
    {
      id: 5,
      sectionTitle: "Canadian Whisky",
      items: [
        {
          id: 0,
          title: "Canadian Club",
          description: "",
          price: 8,
        },
        {
          id: 1,
          title: "Forty Creek",
          description: "",
          price: 8,
        },
        {
          id: 2,
          title: "Wiser's",
          description: "",
          price: 8,
        },
        {
          id: 3,
          title: "Crown Royal",
          description: "",
          price: 9,
        },
      ],
    },
    {
      id: 6,
      sectionTitle: "Scotch Whisky",
      items: [
        {
          id: 0,
          title: "Dewars (1.25 oz)",
          description: "",
          price: 8,
        },
        {
          id: 1,
          title: "Johnnie Walker Red (1.25 oz)",
          description: "",
          price: 8,
        },
        {
          id: 2,
          title: "Johnnie Walker Black (1.25 oz)",
          description: "",
          price: 11,
        },
        {
          id: 3,
          title: "Johnnie Walker Blue (1.25 oz)",
          description: "",
          price: 37,
        },
        {
          id: 4,
          title: "Chivas Regal (1.25 oz)",
          description: "",
          price: 11,
        },
      ],
    },
    {
      id: 7,
      sectionTitle: "Gin",
      items: [
        {
          id: 0,
          title: "Beefeater",
          description: "",
          price: 8,
        },
        {
          id: 1,
          title: "Tanqueray",
          description: "",
          price: 8,
        },
        {
          id: 2,
          title: "Bombay Sapphire",
          description: "",
          price: 9,
        },
        {
          id: 3,
          title: "Hendrick's",
          description: "",
          price: 9,
        },
      ],
    },
    {
      id: 8,
      sectionTitle: "Vodka",
      items: [
        {
          id: 0,
          title: "Absolut",
          description: "",
          price: 8,
        },
        {
          id: 1,
          title: "Kettle One",
          description: "",
          price: 8,
        },
        {
          id: 2,
          title: "Stolichnaya",
          description: "",
          price: 8,
        },
        {
          id: 3,
          title: "Tito's",
          description: "",
          price: 9,
        },
        {
          id: 4,
          title: "Belvedere",
          description: "",
          price: 11,
        },
        {
          id: 5,
          title: "Grey Goose",
          description: "",
          price: 11,
        },
      ],
    },
    {
      id: 9,
      sectionTitle: "Rum",
      items: [
        {
          id: 0,
          title: "Bacardi",
          description: "",
          price: 8,
        },
        {
          id: 1,
          title: "Captain Morgan Dark",
          description: "",
          price: 8,
        },
        {
          id: 2,
          title: "Appleton (Jamaica)",
          description: "",
          price: 8,
        },
      ],
    },
    {
      id: 10,
      sectionTitle: "Irish Whisky",
      items: [
        {
          id: 0,
          title: "Jameson",
          description: "",
          price: 8,
        },
        {
          id: 1,
          title: "Bushmills",
          description: "",
          price: 8,
        },
        {
          id: 2,
          title: "Black Bush",
          description: "",
          price: 9,
        },
      ],
    },
  ],
};

const desserts = {
  id: 4,
  type: "desserts",
  title: "Desserts/After Dinner Drinks",
  description: "",
  image: dessertImage,
  menu: [
    {
      id: 5,
      sectionTitle: "Desserts",
      items: [
        {
          id: 0,
          title: "New York Style Cheesecake w/cherries",
          description: "",
          price: 14,
        },
        { id: 1, title: "Hot Apple Beignets", description: "", price: 12 },
        { id: 2, title: "Vanilla Creme Brulee", description: "", price: 12 },
        { id: 3, title: "Dark Chocolate Cake", description: "", price: 10 },
      ],
    },

    {
      id: 0,
      sectionTitle: "Liqueurs",
      items: [
        {
          id: 0,
          title: "Amaretto",
          description: "",
          price: 8,
        },
        {
          id: 1,
          title: "Bailey's",
          description: "",
          price: 8,
        },
        {
          id: 2,
          title: "Creme De Menthe (White or Green)",
          description: "",
          price: 8,
        },
        {
          id: 3,
          title: "Frangelico",
          description: "",
          price: 8,
        },
        {
          id: 4,
          title: "Irish Mist",
          description: "",
          price: 8,
        },
        {
          id: 5,
          title: "Kahlua",
          description: "",
          price: 8,
        },
        {
          id: 6,
          title: "Sambuca (White or Black)",
          description: "",
          price: 8,
        },
        {
          id: 7,
          title: "Tia Maria",
          description: "",
          price: 8,
        },
        {
          id: 8,
          title: "B & B",
          description: "",
          price: 9,
        },
        {
          id: 9,
          title: "Cointreau",
          description: "",
          price: 9,
        },
        {
          id: 10,
          title: "Drambuie",
          description: "",
          price: 9,
        },
        {
          id: 11,
          title: "Glayva",
          description: "",
          price: 9,
        },
        {
          id: 12,
          title: "Grand Marnier",
          description: "",
          price: 9,
        },
      ],
    },
    {
      id: 1,
      sectionTitle: "Single Malt Scotches",
      items: [
        {
          id: 0,
          title: "McClelland Speyside",
          description: "",
          price: 9.5,
        },
        {
          id: 1,
          title: "Bowmore 12YR",
          description: "",
          price: 10.5,
        },
        {
          id: 2,
          title: "Glenfiddich 12YR",
          description: "",
          price: 10.5,
        },
        {
          id: 3,
          title: "Glenlivet 12YR",
          description: "",
          price: 10.5,
        },
        {
          id: 4,
          title: "Glenmorangie",
          description: "",
          price: 12.5,
        },
        {
          id: 5,
          title: "Laphroaig",
          description: "",
          price: 13.5,
        },
        {
          id: 6,
          title: "Macallan Gold",
          description: "",
          price: 14.5,
        },
        {
          id: 7,
          title: "Dalwhinnie 15YR",
          description: "",
          price: 17,
        },
        {
          id: 8,
          title: "Lagavulin 16YR",
          description: "",
          price: 21.5,
        },
        {
          id: 9,
          title: "Oban 14YR",
          description: "",
          price: 20,
        },
        {
          id: 10,
          title: "Glenfiddich 21YR",
          description: "",
          price: 69,
        },
      ],
    },
    {
      id: 2,
      sectionTitle: "Armagnacs",
      items: [
        {
          id: 0,
          title: "Demontal (V.S.O.P.)",
          description: "",
          price: 10,
        },
      ],
    },
    {
      id: 3,
      sectionTitle: "Brandies",
      items: [
        {
          id: 0,
          title: "Vecchia Romagna (Italy)",
          description: "",
          price: 10,
        },
        {
          id: 1,
          title: "Calvados (France)",
          description: "",
          price: 10,
        },
      ],
    },
    {
      id: 4,
      sectionTitle: "Cognacs",
      items: [
        {
          id: 0,
          title: "Courvoisier (V.S.)",
          description: "",
          price: 10,
        },
        {
          id: 1,
          title: "Courvoisier (V.S.O.P.)",
          description: "",
          price: 13,
        },
        {
          id: 2,
          title: "Remy Martin (V.S.O.P.)",
          description: "",
          price: 13,
        },
        {
          id: 3,
          title: "Courvoisier (X.O.)",
          description: "",
          price: 29,
        },
        {
          id: 4,
          title: "Hennessy (V.S.)",
          description: "",
          price: 10,
        },
      ],
    },

    {
      id: 6,
      sectionTitle: "Coffees",
      items: [
        {
          id: 0,
          title: "Coffee or Tea",
          description: "",
          price: 3.75,
        },
        {
          id: 1,
          title: "Espresso",
          description: "",
          price: 4.25,
        },
        {
          id: 2,
          title: "Cappucino",
          description: "",
          price: 5.25,
        },
        {
          id: 3,
          title: "Cafe Latte",
          description: "",
          price: 6.25,
        },
        {
          id: 4,
          title: "Cafe Au Lait",
          description: "",
          price: 6.25,
        },
      ],
    },
    {
      id: 7,
      sectionTitle: "Specialty Coffees",
      items: [
        {
          id: 0,
          title: "Spanish Coffee",
          description: "Cognac & Tia Maria",
          price: 14,
        },
        {
          id: 1,
          title: "Irish Coffee",
          description: "Irish whiskey & Irish mist",
          price: 14,
        },
        {
          id: 2,
          title: "Shakespeare's Coffee",
          description: "Galliano & dark creme de cacao",
          price: 14,
        },
        {
          id: 3,
          title: "B52 Coffee",
          description: "Bailey's, kahlua & grand marnier",
          price: 16,
        },
        {
          id: 4,
          title: "Brazilian Coffee",
          description: "Cognac, tia maria & grand marnier",
          price: 16,
        },
        {
          id: 5,
          title: "Monte Cristo Coffee",
          description: "Kahula & grand marnier",
          price: 16,
        },
      ],
    },
    {
      id: 8,
      sectionTitle: "Ports",
      items: [
        {
          id: 0,
          title: "Ports - 2 oz. Glass",
          description: "",
          price: 0,
        },
        {
          id: 1,
          title: "Taylor Fladgate's Late Bottle Vintage",
          description: "",
          price: 11,
        },
        // {
        //   id: 2,
        //   title: "Sandemans's Vintage Port",
        //   description: "",
        //   price: 10.95,
        // },
        {
          id: 3,
          title: "Taylor Fladgate's 10YR Tawny Port",
          description: "",
          price: 14,
        },
        {
          id: 4,
          title: "Barao De Vlar Vintage Port 2011",
          description: "",
          price: 18,
        },
        {
          id: 5,
          title: "Graham's Vintage Port 1983",
          description: "",
          price: 38,
        },
      ],
    },
    {
      id: 9,
      sectionTitle: "Grappas",
      items: [
        {
          id: 0,
          title: "Poli",
          description: "",
          price: 13,
        },
        {
          id: 1,
          title: "SASSICAIA",
          description: "",
          price: 26,
        },
      ],
    },
  ],
};

export const getMenu = () => {
  return [lunchAndDinner, wine, cocktails, desserts];
};
