import React from "react";

import { Carousel } from "react-bootstrap";

import bg1 from "../../assets/images/bgsteak_2019.jpg";
import bg2 from "../../assets/images/bgseafood_2019.jpg";
import bg3 from "../../assets/images/bgdessert_2019.jpg";
import bg4 from "../../assets/images/bgwine_2019.jpg";

const Caption = () => (
  <Carousel.Caption>
    <h3>Celebrating 50 Years in Hamilton!</h3>
  </Carousel.Caption>
);

const Slider = () => {
  return (
    <div
      className="use-bootstrap"
      style={{ maxWidth: "1600px", margin: "auto" }}
    >
      <Carousel indicators={false} interval={5000}>
        <Carousel.Item>
          <img className="d-block w-100" src={bg1} alt="First slide" />

          <Caption />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={bg2} alt="Second slide" />

          <Caption />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={bg3} alt="Third slide" />

          <Caption />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={bg4} alt="Fourth slide" />

          <Caption />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Slider;
