import React from "react";

import chefImage from "../../assets/images/chef.png";
import awardImage from "../../assets/images/consumer-choice-award-2018.png";

import diningImage1 from "../../assets/images/dining1.png";
import diningImage2 from "../../assets/images/dining2.png";

import background from "../../assets/images/aboutbg.jpg";

const Information = () => {
  const style = {
    background: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "6rem 0",
  };

  const overlay = {
    background: "rgba(0,0,0,0.85)",
    padding: "0.5rem 2rem",
  };
  return (
    <>
      <div style={style}>
        <div className="container" style={overlay}>
          <div className="section">
            <h1>About Us</h1>

            <div className="flex" style={{ alignItems: "center" }}>
              <div>
                <p>
                  While it's true Hamilton's best-known restaurant for fine
                  dining is Shakespeare's Dining Lounge, how many people would
                  know that it also serves{" "}
                  <span>
                    OSTRICH FILET MIGNON, BUFFALO STEAKS OR WILD BOAR?
                  </span>{" "}
                  When Chef Franco Putignano started his restaurant in 1969, it
                  began as a steak house. But over the years, as the public's
                  tastes changed, so did the menu. Now customers can order not
                  only prime U.S. choice corn fed beef steaks, but also wild
                  game such as caribou and venison.
                </p>

                <p>
                  Shakespeare's also provides an extensive selection of fish,
                  including live lobster, fresh salmon, sea bass, trout,
                  pickerel, red snapper and swordfish. The fish, just like
                  everything else that is served, is personally selected each
                  morning from Hamilton suppliers to ensure freshness.
                </p>
              </div>

              <img
                src={awardImage}
                alt="Award"
                style={{ maxWidth: "700px", maxHeight: "500px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h1 className="text-center">The Chef's Bio</h1>
        <div className="section flex">
          <img src={chefImage} alt="Chef" />

          <div>
            <p className="text-center text-secondary">
              Franco Putignano is a chef and owner of Shakespeare's and has more
              than 50 years experience in food Industry.
            </p>

            <p className="text-center">
              "I feel proud of my family, my staff, and my restaurant. I'm proud
              to have been a part of Hamilton for 50 years."
            </p>

            <p>
              When Chef Franco Putignano started his restaurant in 1969, it
              began as a steak house. But over the years, as the public's tastes
              changed, so did the menu. Now customers can order not only prime
              U.S. choice corn fed beef steaks, but also wild game such as
              caribou and venison.
            </p>

            <p>
              Shakespeare's also provides an extensive selection of fish,
              including live lobster, fresh salmon, sea bass, trout, pickerel,
              red snapper and swordfish. The fish, just like everything else
              that is served, is personally selected each morning from Hamilton
              suppliers to ensure freshness.
            </p>

            <p>
              In addition to his efforts to bring his customers the finest in
              dining fare, he offers one of the best wine selections in the
              city. "I'm very strong on quality, freshness and service." Mr
              Putignano explains. "To me, good food, good wine and good service
              all go together." he adds. "I want people to have the best dining
              experience possible, and I want them to feel at home when they're
              here, "Exotic dishes and quality steaks aren't the only things
              emphasized on Shakespeare's menu.
            </p>

            <p>
              The result of the care and service to customers means that
              Shakespeare's has managed to weather the recession and keep a
              steady clientele, a large number of whom are regular customers.
            </p>

            <p>
              In addition, celebrities such as Tom Jones, Ricardo Montalban,
              Harry Belafonte, Liberace, Rock Hudson, Kathleen Robertson, Gordie
              Howe, Wayne Gretzky, Tom Cochrane, Daniel Lanois, Marc Garneau,
              Bernie Falonie, Darryl Sittler, Mark Messier, Martin Short, Angelo
              Mosca, Janet Jones and Peter Ustinov have all made their way to
              Shakespeare's when they were in town.
            </p>

            <p>
              Mr. Putignano's son Leo manages the dining room, while his
              daughter Christina bartends in the restaurant. Their mother,
              Nicole, handles the book-keeping and weekend hosting duties and
              his son-in-law, Peter Smith, also assists in managing the
              restaurant. The arrangement enables Mr. Putignano to see his
              family through the week while working from Monday to Saturday. "I
              don't mind the long days," says Mr. Putignano. "I've got the
              business in the blood, and I love people. I work longer hours now,
              but I love it," he adds. "During the recession, we didn't cut back
              on quality. We always tried to do better. And it worked."
            </p>

            <p>
              In 1994, Mr Putignano was issued a certificate by the City,
              honouring him for his 25 years in the business. Included among his
              many other honours, is his nomination for Iron Man of the Year in
              1995 from the Hamilton & District Chamber of Commerce and the
              bestowing of three Spectator Reader's Choice Awards for Best Steak
              House, Best Sea Food Restaurant and Best French Restaurant. The
              awards are a fitting recognition for his dedication to providing
              the best to his customers for so long.
            </p>

            <p>
              "Nothing comes easy. You have to work hard," Mr Putignano says, "I
              feel proud of my family, my staff, and my restaurant. I'm proud to
              have been a part of Hamilton for 50 years."
            </p>

            <strong>Franco Putignano</strong>
          </div>
        </div>

        <div className="section">
          <h1 className="text-center text-secondary">Private Dining</h1>
          <div className="flex">
            <div className="flex">
              <img src={diningImage1} alt="" style={{ maxWidth: "400px" }} />
              <img src={diningImage2} alt="" style={{ maxWidth: "400px" }} />
            </div>

            <div>
              <h2 className="text-secondary">
                WEDDING? PRIVATE PARTIES? CORPORATE EVENT? NO WORRIES
              </h2>

              <p>
                Our private dining room is a beautifully decorated room which is
                suitable for any special event. Fitting up to 20 seats, this
                artistically crafted area is capable of serving all your
                catering needs at a very special price. The private dining room
                is perfect for any type of social get-together, whether it be
                for hosting clients or an intimate gathering of close friends.
                Also great for:
              </p>

              <ul
                style={{ margin: "0 0 3rem 3rem", fontSize: "1.25rem" }}
                className="text-secondary"
              >
                <li>Birthdays</li>
                <li>Anniversaries</li>
                <li>Weddings</li>
                <li>Celebration or Party</li>
                <li>Business Events</li>
                <li>Business Meetings</li>
              </ul>

              <p>
                <div>Call us for a quote:</div>
                <a href="tel:905-528-0689" className="text-secondary">
                  (905) 528-0689
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Information;
