import React from "react";

import MenuList from "./MenuList";

const Menu = () => {
  return (
    <div className="container">
      <h1 className="text-primary text-center">Menu</h1>

      <MenuList />
    </div>
  );
};

export default Menu;
