import React from "react";
import { Link } from "react-router-dom";

import dinnerImg from "../../assets/images/dinner.jpg";
import lunchImg from "../../assets/images/lunch.png";
import wineImg from "../../assets/images/wine.png";
import cocktailImg from "../../assets/images/cocktail.png";
import dessertImg from "../../assets/images/dessert.png";

const MenuList = () => {
  return (
    <div className="flex" style={{ marginBottom: "3rem" }}>
      <Link to="/menu/lunch-dinner" className="menu-list-item">
        <div className="item-overlay">
          <img src={dinnerImg} alt="" />
        </div>
        <h2 className="text-center">Lunch / Dinner</h2>
      </Link>

      {/* <Link to="/menu/lunch" className="menu-list-item">
        <div className="item-overlay">
          <img src={lunchImg} alt="" />
        </div>
        <h2 className="text-center">Lunch</h2>
      </Link> */}

      <Link to="/menu/wine" className="menu-list-item">
        <div className="item-overlay">
          <img src={wineImg} alt="" />
        </div>
        <h2 className="text-center">Wine</h2>
      </Link>

      <Link to="/menu/dessert" className="menu-list-item">
        <div className="item-overlay">
          <img src={dessertImg} alt="" />
        </div>
        <h2 className="text-center">Dessert</h2>
      </Link>

      <Link to="/menu/cocktails" className="menu-list-item">
        <div className="item-overlay">
          <img src={cocktailImg} alt="" />
        </div>
        <h2 className="text-center">Cocktails</h2>
      </Link>
    </div>
  );
};

export default MenuList;
