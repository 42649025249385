import React from "react";

const More = () => {
  return (
    <div>
      <h1>More</h1>
    </div>
  );
};

export default More;
