export const appetizers = [
  { id: 0, name: "Soup of the Day", price: 7, additionalInfo: "" },
  { id: 1, name: "Jumbo Shrimp Cocktail", price: 30, additionalInfo: "" },
  { id: 2, name: "Manhattan Seafood Chowder", price: 12, additionalInfo: "" },
  {
    id: 3,
    name: "Relish Tray",
    price: 5,
    additionalInfo: "Pickles, kalamata olives & feta (per person)",
  },
  {
    id: 4,
    name: "Smoked Salmon",
    price: 14,
    additionalInfo: "w/ olive oil, onions, & capers",
  },
  { id: 5, name: "Charcuterie", price: 35, additionalInfo: "For two" },
];

export const salads = [
  { id: 0, name: "Caeser Salad", price: 15, additionalInfo: "" },
  {
    id: 1,
    name: "Caprese Salad",
    price: 15,
    additionalInfo: "Tomatoes, bocconcini, onions, & basil",
  },
  {
    id: 2,
    name: "Iceberg Wedge w/ Blue Cheese Dressing",
    price: 13,
    additionalInfo: "",
  },
  {
    id: 3,
    name: "Spinach Salad",
    price: 15,
    additionalInfo: "Mushrooms, onions, bacon",
  },
];

export const entrees = [
  {
    id: 0,
    name: "Filet Mignon",
    price: 46,
    additionalInfo: "Bacon wrapped 9 oz.",
  },
  { id: 1, name: "New York Striploin", price: 46, additionalInfo: "12 oz." },
  { id: 2, name: "Ribeye Steak", price: 45, additionalInfo: "12 oz." },
  {
    id: 13,
    name: "Bone in Ribeye Steak",
    price: 65,
    additionalInfo: "24 oz.",
  },
  {
    id: 3,
    name: "Sir John Falstaff Pork Back Ribs",
    price: 34,
    additionalInfo: "Full rack",
  },
  { id: 4, name: "Grilled Tiger Shrimps", price: 39, additionalInfo: "" },
  {
    id: 5,
    name: "Filet of Atlantic Salmon",
    price: 28,
    additionalInfo: "Oven baked",
  },
  {
    id: 6,
    name: "Alaskan King Crab Legs",
    price: 99,
    additionalInfo: "1 lb",
  },
  { id: 7, name: "Lobster Tail", price: 50, additionalInfo: "10 oz." },
  {
    id: 8,
    name: "Surf and Turf",
    price: 75,
    additionalInfo: "10 oz. lobster tail & 5 oz. bacon wrapped fillet",
  },
  {
    id: 9,
    name: "Gourmet Burger",
    price: 25,
    additionalInfo:
      "Served with bacon, cheese, lettuce, tomato, pickle, onion & side of fries",
  },
  { id: 10, name: "Grilled Branzino", price: 39, additionalInfo: "" },
  { id: 11, name: "Grilled Swordfish", price: 42, additionalInfo: "" },
  {
    id: 12,
    name: "Filet of Trout",
    price: 35,
    additionalInfo: "Oven baked",
  },
];

export const pasta = [
  {
    id: 0,
    name: "Chicken Parmigiana w/Penne Pomodoro",
    price: 28,
    additionalInfo: "",
  },
  {
    id: 1,
    name: "Meat Canneloni",
    price: 24,
    additionalInfo: "Tomato sauce",
  },
  {
    id: 2,
    name: "Penne Pomodoro",
    price: 19,
    additionalInfo: "Tomato, garlic, & basil",
  },
];

export const addons = [
  {
    id: 0,
    name: "Additional Garlic Bread",
    price: 5,
    additionalInfo: "Per loaf",
  },
  { id: 1, name: "Mushrooms", price: 8, additionalInfo: "" },
  { id: 2, name: "Onion Rings", price: 8, additionalInfo: "" },
  { id: 3, name: "French Fries", price: 6, additionalInfo: "" },
  {
    id: 4,
    name: "Shakespeare's Spice",
    price: 5,
    additionalInfo: "100 ml",
  },
  {
    id: 5,
    name: "Blue Cheese Dressing",
    price: 14,
    additionalInfo: "500 ml",
  },
];

export const cookYourOwnCuts = [
  {
    id: 0,
    name: "Filet Mignon",
    price: 46,
    additionalInfo: "Bacon wrapped 9 oz.",
  },
  { id: 1, name: "New York Sirloin", price: 46, additionalInfo: "12 oz." },
  { id: 2, name: "Ribeye Steak", price: 25, additionalInfo: "12 oz." },
];

export const desserts = [
  { id: 0, name: "Dark Chocolate Cake", price: 10, additionalInfo: "" },
  {
    id: 1,
    name: "New York Style Cherry Cheesecake",
    price: 14,
    additionalInfo: "",
  },
  { id: 2, name: "Vanilla Crème Brûlée", price: 12, additionalInfo: "" },
];
